import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell d’aquest bolet pot mesurar fins a 14 cm de diàmetre, primer acampanat que poc a poc s’obre i de color marró ocraci i a vegades verd oliva. Té el marge estriat de forma ben clara. Les nombroses làmines de davall tenen moltes lamel·les connectades entre si i són de color argila que amb el temps s’oscureixen. El peu és llarg, fusiforme, amb la part superior estriada i amb tons violacis. Presenta un decorat característic en forma de ziga-zaga que són restes de la cortina. La resta de peu és blanc. Les espores són de color rovellat en massa, berrugoses, en forma d’ametlla allargada, de 13-15 x 7-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      